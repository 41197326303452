::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--light-gray);
}

::-webkit-scrollbar-thumb {
    background-color: var(--black);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary-color);
}

* {
    box-sizing: border-box !important;
}
