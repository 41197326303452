@import url('fonts/fonts.css');

:root {
    /* ------- colors ------- */
    --black: #02100e;
    --darkest-gray: rgb(34, 34, 34);
    --dark-gray: rgb(68, 67, 67);
    --lightest-gray: rgb(245, 245, 245);
    --light-gray: rgb(232, 231, 231);
    --gray: rgb(154, 154, 154);
    --white: #ffffff;
    --primary-color: #006d55;
    --secondary-color: #18baa6;
    --secondary-color-light: rgb(235, 241, 240);
    --tertiary-color: rgba(22, 37, 35, 1);
    --primary-text-color: var(--black);
    --secondary-text-color: var(--light-gray);
    --link-color: #14abff;
    --danger-color: #dc3545;
    --gradient-green-to-blue: linear-gradient(
        90deg,
        var(--secondary-color) 0%,
        var(--link-color) 100%
    );

    /* ---------------------- */
    --font-xl: 40px;
    --font-lg: 32px;
    --font-md: 26px;
    --font-md-base: 24px;
    --font-base: 20px;
    --font-base-sm: 18px;
    --font-sm: 16px;
    --line-height-xl: 48px;
    --line-height-lg: 40px;
    --line-height-md: 32px;
    --line-height-md-base: 30px;
    --line-height-base: 28px;
    --line-height-base-sm: 26px;
    --line-height-sm: 24px;
    --button-box-shadow: 0px 4px 4px rgba(158, 143, 143, 0.25);

    /* ---------------------- */
    --bp-mini: 600px;
    --bp-mobile: 768px;
    --bp-table: 1169px;
    --bp-desktop: 1246px;

    /* ------- fonts ------- */
    --primary-font: 'Eudoxus Sans', sans-serif;
    /* --------------------- */
}

body {
    font-family: var(--primary-font);
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: var(--primary-font);
}
